export enum APP_PERMISSION {
  // TASK
  TASK_MANAGEMENT_VIEW = 'TASK_MANAGEMENT_VIEW',
  VIEW_PUBLIC_TASK = 'VIEW_PUBLIC_TASK',
  EDIT_PUBLIC_TASK = 'EDIT_PUBLIC_TASK',
  ADD_PUBLIC_TASK = 'ADD_PUBLIC_TASK',
  HISTORY_LOG_VIEW = 'HISTORY_LOG_VIEW',
  TASK_DELETE = 'TASK_DELETE',

  // APPOINTMENT
  PUBLIC_APPOINTMENT_VIEW = 'PUBLIC_APPOINTMENT_VIEW',
  APPOINTMENT_DELETE = 'APPOINTMENT_DELETE',
  APPOINTMENT_PUBLIC_ADD = 'APPOINTMENT_PUBLIC_ADD',
  EDIT_PUBLIC_APPOINTMENT = 'EDIT_PUBLIC_APPOINTMENT',
  APPOINTMENT_VIEW = 'APPOINTMENT_VIEW',

  // SETTING
  SETTINGS_VIEW = 'SETTINGS_VIEW',

  // User
  LIST_USER_VIEW = 'LIST_USER_VIEW',
  USER_CREATE_EDIT = 'USER_CREATE_EDIT',
  USER_EXPORT = 'USER_EXPORT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  USER_ACTIVE_DEACTIVE = 'USER_ACTIVE_DEACTIVE',
  USER_HIDE = 'USER_HIDE',

  // Admin content
  POST_VIEW = 'POST_VIEW',
  POST_CREATE_EDIT = 'POST_CREATE_EDIT',
  POST_DELETE = 'POST_DELETE',
  LINK_PIN = 'LINK_PIN',
  LINK_VIEW = 'LINK_VIEW',
  BANNER_VIEW = 'BANNER_VIEW',
  BANNER_EDIT = 'BANNER_EDIT',
  POST_PIN = 'POST_PIN',
  LINK_DELETE = 'LINK_DELETE',
  LINK_ADD_EDIT = 'LINK_ADD_EDIT',

  // Permission
  GROUP_INFORMATION_EDIT = 'GROUP_INFORMATION_EDIT',
  GROUP_ROLE_DELETE = 'GROUP_ROLE_DELETE',
  PERMISSIONS_VIEW = 'PERMISSIONS_VIEW',
  GROUP_ROLE_CREATE = 'GROUP_ROLE_CREATE',
  PERMISSION_EDIT = 'PERMISSION_EDIT',
  MEMBER_ADD_REMOVE = 'MEMBER_ADD_REMOVE',

  //User group
  MEMBER_VIEWER_ADD_REMOVE = 'MEMBER_VIEWER_ADD_REMOVE',
  USER_GROUPS_CREATE = 'USER_GROUPS_CREATE',
  USER_GROUPS_VIEW = 'USER_GROUPS_VIEW',

  // Org chart
  ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
  ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',

  DATA_CONFIGURATION = 'DATA_CONFIGURATION',

  // Dashboard
  DASHBOARD_LEAD_VIEW = 'DASHBOARD_LEAD_VIEW',
  DASHBOARD_POLICY_VIEW = 'DASHBOARD_POLICY_VIEW',
  DASHBOARD_PERFORMANCE_VIEW = 'DASHBOARD_PERFORMANCE_VIEW',
  DASHBOARD_USER_VIEW = 'DASHBOARD_USER_VIEW',
  CUSTOMER_CHART = 'CUSTOMER_CHART',
  CHART_EXPORT = 'CHART_EXPORT',

  // SALE HUBS
  SALES_HUB_VIEW = 'SALES_HUB_VIEW',

  // Note
  NOTE_APPEND_EDIT = 'NOTE_EDIT',
  NOTE_APPEND_DELETE = 'NOTE_DELETE',
  NOTE_ADD_APPEND = 'NOTE_ADD_APPEND',
  NOTE_PIN = 'NOTE_PIN',
  NOTE_EXPORT = 'NOTE_EXPORT',
  NOTE_MANAGEMENT_VIEW = 'NOTE_MANAGEMENT_VIEW',
  NOTE_DETAIL_VIEW = 'NOTE_DETAIL_VIEW',
  EDIT_ANY_NOTE = 'EDIT_ANY_NOTE',
  DELETE_ANY_NOTE = 'DELETE_ANY_NOTE',

  // Report
  REPORT_EXPORT = 'REPORT_EXPORT',
  REPORT_LEAD_RUN = 'REPORT_LEAD_RUN',
  REPORT_PRODUCTION_DETAILS_RUN = 'REPORT_PRODUCTION_DETAILS_RUN',
  REPORT_PERFORMANCE_RUN = 'REPORT_PERFORMANCE_RUN',

  // Lead
  IMPORT_LEAD_CREATE = 'IMPORT_LEAD_CREATE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  STAGE_CHANGE = 'STAGE_CHANGE',
  CSR_CHANGE = 'CSR_CHANGE',
  OWNER_CHANGE = 'OWNER_CHANGE',
  AGENT_CHANGE = 'AGENT_CHANGE',
  SOURCE_CHANGE = 'SOURCE_CHANGE',
  LEAD_EXPORT = 'LEAD_EXPORT',
  LEAD_EDIT = 'LEAD_EDIT',
  RELATIONSHIP_DELETE = 'RELATIONSHIP_DELETE',
  HIDDEN_LEAD_VIEW = 'HIDDEN_LEAD_VIEW',
  RELATIONSHIP_EDIT = 'RELATIONSHIP_EDIT',
  ALERT_DELETE = 'ALERT_DELETE',
  COMMENT_ADD_APPEND_PIN_EDIT = 'COMMENT_ADD_APPEND_PIN_EDIT',
  ALERT_ADD_PIN_EDIT = 'ALERT_ADD_PIN_EDIT',
  COMMENT_DELETE = 'COMMENT_DELETE',
  LIST_ATTACHMENT_VIEW = 'LIST_ATTACHMENT_VIEW',
  ATTACHMENT_OPEN = 'ATTACHMENT_OPEN',
  FILE_ATTACHMENT_UPLOAD = 'FILE_ATTACHMENT_UPLOAD',
  ATTACHMENT_EDIT = 'ATTACHMENT_EDIT',
  ATTACHMENT_DOWNLOAD = 'ATTACHMENT_DOWNLOAD',
  ATTACHMENT_DELETE = 'ATTACHMENT_DELETE',
  CONFIDENTIAL_ATTACHMENT_OPEN = 'CONFIDENTIAL_ATTACHMENT_OPEN',
  CONFIDENTIAL_ATTACHMENT_EDIT = 'CONFIDENTIAL_ATTACHMENT_EDIT',
  PHONE_NUMBER_EDIT = 'PHONE_NUMBER_EDIT',
  LEAD_HIDE = 'LEAD_HIDE',
  RELATIONSHIP_ADD = 'RELATIONSHIP_ADD',

  CLAIMS_MANAGEMENT = 'CLAIMS_MANAGEMENT',

  // Policy
  POLICY_CREATE = 'POLICY_CREATE',
  POLICY_MANAGEMENT_VIEW = 'POLICY_MANAGEMENT_VIEW',
  POLICY_MANAGEMENT_UPDATE = 'POLICY_MANAGEMENT_UPDATE',
  POLICY_MANAGEMENT_EXPORT = 'POLICY_MANAGEMENT_EXPORT',
  EDIT_POLICY_CHANGE_POLICY_STATUS = 'EDIT_POLICY_CHANGE_POLICY_STATUS',
  POLICY_HIDE = 'POLICY_HIDE',
  VIEW_COMMENT_APPEND_DETAILS = 'VIEW_COMMENT_APPEND_DETAILS',
  COMMISSION_PERCENTAGE_EDIT = 'COMMISSION_PERCENTAGE_EDIT',
  COMMISSION_OWNER_EDIT = 'COMMISSION_OWNER_EDIT',
  COMMISSION_AGENT_EDIT = 'COMMISSION_AGENT_EDIT',
  COMMISSION_TEAM_EDIT = 'COMMISSION_TEAM_EDIT',
  POLICY_NUMBER_EDIT = 'POLICY_NUMBER_EDIT',

  // NOTIFICATION
  NOTIFICATION_VIEW = 'NOTIFICATION_VIEW',
  NOTIFICATION_EDIT = 'NOTIFICATION_EDIT',

  // FACEBOOK
  FACEBOOK_LOGGED_IN = 'FACEBOOK_LOGGED_IN',

  // MARKETING
  MARKETING_VIEW = 'MARKETING_VIEW',
  MKT_CAMPAIGNS = 'MKT_CAMPAIGNS',
  MKT_AUDIENCES = 'MKT_AUDIENCES',
  MKT_DASHBOARD = 'MKT_DASHBOARD',
  MKT_BILLING_PAYMENTS = 'BILLING_PAYMENT',
  MKT_INTEGRATION = 'MKT_INTEGRATION',
  MKT_DATA_SOURCES = 'DATA_SOURCE'
}
